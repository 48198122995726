import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import RefreshSvg from 'src/assets/icons/customIcons/Refresh.svg'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'

export default function MaintenanceModePage(): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('system:technical_works_are_underway')} />

      <div className="flex h-screen items-center justify-center">
        <div className="flex w-full max-w-[900px] flex-col items-center justify-between rounded border border-none bg-card px-3 py-5">
          <FontAwesomeIcon icon={ProSolidSvgIcons.faConstruction} size="3x" className="text-lightSecondaryWarning" />
          <div className="ml-2 flex flex-col items-center justify-center">
            <h1 className="text-center text-2xl font-bold">{t('system:technical_works_are_underway')}</h1>
            <h2 className="mb-2 text-center text-xl">{t('system:technical_works_are_underway_description')}</h2>
            <Button
              variant="blue"
              className="flex w-auto items-center rounded px-3 py-2"
              title={t('group:groups_refresh')}
              onClick={() => window.location.reload()}
            >
              <img src={RefreshSvg} alt="refresh" />
              <span className="ml-2 whitespace-nowrap">{t('system:refresh_page')}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
