import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import * as Api from 'src/api'
import Argus from 'src/assets/icons/customIcons/Argus'
import { EngIcon } from 'src/assets/icons/customIcons/Eng'
import { GeoIcon } from 'src/assets/icons/customIcons/Geo'
import BannerLogo from 'src/assets/icons/customIcons/page-icons/Logo.svg'
import loginImage from 'src/assets/png/login_image.png'
import env from 'src/helpers/env'
import { authObservableValue } from 'src/hooks/auth/auth'
import { refreshTokenStorage } from 'src/hooks/auth/refreshTokenStorage'
import { useLocale } from 'src/hooks/locale/locale'
import { useLocaleLinks, useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import Footer from 'src/tailwind/layout/Footer'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'
import * as Yup from 'yup'
import LoginWithGoogle from '../../components/LoginWithGoogle'

export default function LoginPage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('system:login')} />
      <PageContent />
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [clickCount, setClickCount] = React.useState<number>(0)
  const locale = useLocale()
  const localeLinks = useLocaleLinks()
  const t = useTranslatable()
  const environmentName = env.APP_ENV

  return (
    <div
      style={environmentName === 'PRODUCTION' ? { height: '100vh' } : { height: 'calc(100vh - 35px)' }}
      className="relative flex bg-primaryWhite"
    >
      <div className="relative w-full md:w-[35%] xxs:hidden xxs:w-full xs:hidden xs:w-full">
        <img src={loginImage} className="size-full object-cover" />
        <div className="absolute left-1/2 top-1/4 hidden w-[90%] -translate-x-1/2 -translate-y-1/4 flex-col items-center md:flex">
          <img alt="argus-logo" src={BannerLogo} />
          <div className="mt-4 w-full border-y border-white py-3">
            <h2 className="text-center text-headline font-semibold text-white xxs:text-[20px] xs:text-[20px]">
              {t('system:ilia_state_university')}
            </h2>
          </div>
        </div>
      </div>
      <div className="flex h-screen w-[90%] min-w-[275px] flex-col items-center justify-between bg-primaryWhite px-6 pb-2 pt-10 md:w-[65%] xxs:h-[90vh] xxs:w-full xxs:overflow-y-scroll xs:h-[90vh] xs:w-full xs:overflow-y-scroll">
        <div className="w-full">
          <div className="flex w-full items-center justify-end">
            <ul className="flex items-center gap-[22px]">
              {/* <li
              onClick={() => void App.Theme.TOGGLE()}
              className='flex gap-[8px] hover:bg-card cursor-pointer rounded-[18px] px-4 py-2'
            >
              <ThemeIcon /> {t('system:change_theme')}
            </li> */}
              <li>
                <div className="cursor-pointer rounded-[18px] p-2 hover:bg-card" title={t('common:change_language')}>
                  <Link to={locale === 'en' ? localeLinks.ka : localeLinks.en}>
                    <div className="flex gap-[6px] text-primaryTextColor">
                      {locale === 'en' ? <GeoIcon /> : <EngIcon />} <span>{t('common:switch_language')}</span>
                    </div>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div className="flex justify-center">
            <div className="mx-auto mt-[8vh] w-full max-w-[328px] p-0 pb-6 md:p-6">
              <div
                className="flex flex-col items-center"
                onClick={() => clickCount < 5 && setClickCount(clickCount + 1)}
              >
                <div className="text-center text-headline font-semibold text-primaryRed xxs:text-[20px] xs:text-[20px]">
                  {t('system:argus')}
                </div>
                <Argus className="my-3 w-[60px] cursor-pointer duration-500" />
              </div>
              <div className="mt-[60px] flex items-center justify-center">
                <LoginWithGoogle />
              </div>

              {(env.APP_ENV === 'LOCAL' || clickCount >= 5) && (
                <>
                  <div className="flex items-center justify-between">
                    <div className="h-px w-[42%] bg-gray-200" />{' '}
                    <span className="my-[30px] text-caption">{t('common:or')}</span>{' '}
                    <div className="h-px w-[42%] bg-gray-200" />
                  </div>
                  <Form
                    initialValues={{ login: '', password: '' }}
                    validationSchema={Yup.object().shape({
                      login: Yup.string().required(t('error:personal_number_is_required')),
                      password: Yup.string()
                        .min(6, t('error:the_password_must_be_6_characters_long'))
                        .required(t('message:password_is_required')),
                    })}
                    onSubmit={async (values) => {
                      const authObject = await Api.login({
                        body: {
                          clientId: '1',
                          grantType: 'internal',
                          login: values.login,
                          password: values.password,
                        },
                        headers: { 'Accept-Language': locale },
                      })

                      const user = await Api.getAuthUser({
                        headers: {
                          'Accept-Language': locale,
                          Authorization: Api.getAuthorization(authObject),
                        },
                      })

                      const profile = user.profiles[0]!

                      refreshTokenStorage.setValue({ authObject, profileId: profile.id })

                      authObservableValue.setValue({
                        state: 'Authenticated',
                        user,
                        profile,
                      })
                    }}
                  >
                    <FormError />

                    <TextInput
                      data-testid="Login/username"
                      type="text"
                      placeholder={t('person:pers_number')}
                      name="login"
                    />
                    <TextInput
                      data-testid="Login/password"
                      type="password"
                      placeholder={t('person:password')}
                      name="password"
                    />
                    <div className="flex justify-end">
                      <Button type="submit" variant="red" data-testid="Login/submit">
                        {t('system:login')}
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}
