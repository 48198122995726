import * as React from 'react'
import { toast } from 'react-toastify'
import env from 'src/helpers/env'
import { authEventEmitter } from 'src/hooks/auth/authEventEmitter'
import { prepare } from 'src/hooks/auth/google'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'

export default function LoginWithGoogle(): React.ReactElement | null {
  const t = useTranslatable()
  const clientRef = React.useRef<google.accounts.oauth2.TokenClient | null>(null)

  React.useEffect(() => {
    void prepare().then(() => {
      try {
        // https://developers.google.com/identity/oauth2/web/guides/use-token-model
        clientRef.current = google.accounts.oauth2.initTokenClient({
          client_id: env.GOOGLE_CLIENT_ID,
          scope: [
            'https://www.googleapis.com/auth/userinfo.email',
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/calendar.readonly',
          ].join(' '),
          hosted_domain: 'iliauni.edu.ge',
          callback: (response) => {
            authEventEmitter.emit('GOOGLE_TOKEN_RESPONSE', response)
          },
        })
      } catch (error) {
        toast.error(t('error:an_error_occurred'))
      }
    })
  }, [t])

  return (
    <Button
      onClick={() => clientRef.current?.requestAccessToken()}
      variant="red"
      data-testid="login-with-google"
      className="w-[190px]"
    >
      {t('system:authorization')}
    </Button>
  )
}
