import classnames from 'classnames'
import * as React from 'react'
import { DropDownIcon } from 'src/assets/icons/customIcons/Dropdown'
import { actionProps } from 'src/helpers/fns'
import { useDropdown } from 'src/helpers/hooks'
import { useAuth, useAuthActions } from 'src/hooks/auth/auth'
import { useTranslatable } from 'src/hooks/locale/utils'

export default function ProfileSwitcher(): React.ReactElement | null {
  const auth = useAuth()
  const actions = useAuthActions()
  const t = useTranslatable()
  const { show, setShow, ref, id } = useDropdown<HTMLAnchorElement>()

  if (auth.state !== 'Authenticated') return null

  return (
    <div className="mr-2 list-none" data-testid="ProfileSwitcher">
      {auth.user.profiles.length === 1 ? (
        <div className="px-[12px] py-[8px]">
          <span className="text-caption" title={t(`degrees:${auth.profile.degree}`)}>
            {t(`degrees:${auth.profile.degree}`).length > 35
              ? t(`degrees:${auth.profile.degree}`).slice(0, 35) + '...'
              : t(`degrees:${auth.profile.degree}`)}
          </span>
        </div>
      ) : (
        <li className={classnames({ show })}>
          <a
            className="flex cursor-pointer items-center rounded-[18px] px-[12px] py-[8px] text-caption hover:bg-card"
            id={id}
            role="button"
            aria-haspopup="true"
            aria-expanded={show ? 'true' : 'false'}
            ref={ref}
            {...actionProps(() => void setShow(!show))}
            data-testid="ProfileSwitcher/button"
          >
            {t(`degrees:${auth.profile.degree}`)}
            <span className="ml-1">
              <DropDownIcon />
            </span>
          </a>
          <div
            className={classnames('absolute right-12 flex flex-col rounded-card border-none bg-primaryWhite p-4', {
              block: show,
              hidden: !show,
            })}
            aria-labelledby={id}
            style={{ boxShadow: '0px 1px 14px #00000033' }}
            data-testid="ProfileSwitcher/Menu"
          >
            {auth.user.profiles.map((profile, key) => (
              <a
                key={key}
                className={classnames(
                  'mb-[5px] flex min-w-[150px] cursor-pointer rounded-[8px] px-[12px] py-[16px] text-left text-primaryTextColor hover:bg-tabColor',
                  {
                    'bg-tabColor': profile.id === auth.profile.id,
                  }
                )}
                {...actionProps(() => void actions.SWITCH_PROFILE(profile))}
                data-testid="ProfileSwitcher/Menu/item"
              >
                {t(`degrees:${profile.degree}`)}
              </a>
            ))}
          </div>
        </li>
      )}
    </div>
  )
}
